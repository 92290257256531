import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Container = ({ className: additionalClassName, children, ...otherProps }) => {
  const className = classNames(
    'relative max-w-[1216px] mx-auto px-4 md:px-6 lg:px-10 xl:px-0',
    additionalClassName
  );

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  className: null,
};

export default Container;
