import { Popover } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import React, { Fragment } from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Link from 'components/shared/link';
import MobileMenu from 'components/shared/mobile-menu';
import BehaviorGenomeIcon from 'icons/behavior-genome.inline.svg';
import DataMonetizationIcon from 'icons/data-monetization.inline.svg';
import DataScienceIcon from 'icons/data-science.inline.svg';
import FinanceIcon from 'icons/finance.inline.svg';
import GrowthIcon from 'icons/growth.inline.svg';
import IntelligenceIcon from 'icons/intelligence.inline.svg';
import MarketingAdvertisingIcon from 'icons/marketing-advertising.inline.svg';
import ModelingIcon from 'icons/modeling.inline.svg';
import PersonalizationIcon from 'icons/personalization.inline.svg';
import Logo from 'images/logo.inline.svg';

const items = [
  {
    label: 'Platform',
    to: '/platform',
    items: [
      {
        icon: IntelligenceIcon,
        label: 'Intelligence',
        to: '/intelligence',
      },
      {
        icon: GrowthIcon,
        label: 'Growth',
        to: '/growth',
      },
      {
        icon: PersonalizationIcon,
        label: 'Personalization',
        to: '/personalization',
      },
    ],
    itemsTheme: 'primary-1',
  },
  {
    label: 'Solutions',
    to: '/solutions',
    items: [
      {
        icon: MarketingAdvertisingIcon,
        label: 'Marketing/Advertising',
        to: '/marketing-advertising',
      },
      {
        icon: DataScienceIcon,
        label: 'Data Science',
        to: '/data-science',
      },
      {
        icon: DataMonetizationIcon,
        label: 'Data Monetization',
        to: '/data-monetization',
      },
      {
        icon: FinanceIcon,
        label: 'Finance',
        to: '/finance',
      },
    ],
    itemsTheme: 'primary-2',
  },
  {
    label: 'Technology',
    to: '/behavior-genome',
    items: [
      {
        icon: BehaviorGenomeIcon,
        label: 'Behavior Genome',
        to: '/behavior-genome',
      },
    ],
    itemsTheme: 'primary-1',
  },
  { label: 'About', to: '/about' },
];

const Header = () => (
  <header className="relative z-20 py-7">
    <Popover as={Fragment}>
      {({ open: isOpen }) => (
        <>
          <Container aria-label="Global">
            <nav className="flex items-center justify-between" aria-label="Global">
              <Link to="/">
                <span className="sr-only">Yobi</span>
                <Logo />
              </Link>
              <div className="flex items-center -mr-2 md:hidden">
                <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md bg-gray-50 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="w-6 h-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <div className="hidden md:absolute md:top-1/2 md:left-1/2 md:flex md:space-x-8 lg:space-x-12 md:-translate-y-1/2 md:-translate-x-1/2">
                {items.map(({ label, to, items, itemsTheme }, index) => (
                  <div className="relative" key={index}>
                    <Link className="peer" to={to} type="text" theme="black">
                      {label}
                    </Link>
                    {items && (
                      <div className="absolute bottom-0 invisible transition-all duration-300 scale-95 translate-y-full opacity-0 -left-8 pt-7 hover:scale-100 hover:opacity-100 hover:visible peer-hover:scale-100 peer-hover:opacity-100 peer-hover:visible">
                        <div className="p-8 space-y-8 bg-white rounded-lg shadow-2xl pr-14">
                          {items.map(({ icon: Icon, label, to }, index) => (
                            <Link
                              className={`flex items-center leading-none transition-colors duration-200 hover:text-${itemsTheme}`}
                              to={to}
                              key={index}
                            >
                              <Icon className="flex-shrink-0 w-11 mr-3.5" />
                              <div>
                                <span className="block font-bold whitespace-nowrap">{label}</span>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="hidden md:inline-flex">
                <Button to="#request-demo" size="sm" theme="black">
                  Get Started
                </Button>
              </div>
            </nav>
          </Container>

          <MobileMenu isOpen={isOpen} items={items} />
        </>
      )}
    </Popover>
  </header>
);

export default Header;
