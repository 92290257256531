import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Link from 'components/shared/link';

const commonClassNames =
  'inline-flex font-bold !leading-none whitespace-nowrap rounded outline-none transition-colors duration-200';

const sizeClassNames = {
  sm: 'text-base py-3.5 px-5',
  md: 'text-lg py-3.5 px-5 lg:py-5 lg:px-7 lg:text-xl',
};

const themeClassNames = {
  black: 'text-white bg-black hover:bg-gray-2',
  white: 'text-black bg-white hover:text-primary-1',
  primary: 'text-white bg-primary-1 hover:bg-primary-2',
};

const Button = ({ className: additionalClassName, to, size, theme, children, ...otherProps }) => {
  const className = classNames(
    commonClassNames,
    sizeClassNames[size],
    theme && themeClassNames[theme],
    additionalClassName
  );

  const Tag = to ? Link : 'button';

  return (
    <Tag className={className} to={to} {...otherProps}>
      {children}
    </Tag>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizeClassNames)).isRequired,
  theme: PropTypes.oneOf(Object.keys(themeClassNames)),
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  className: null,
  to: null,
  theme: null,
};

export default Button;
