import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const commonClassNames = 'font-bold';

const sizeClassNames = {
  xl: 'text-4xl md:text-5xl lg:text-6xl',
  lg: 'text-4xl lg:text-5xl',
  md: 'text-3xl lg:text-4xl',
  sm: 'text-xl lg:text-2xl',
};

const themeClassNames = {
  black: 'text-black',
  white: 'text-white',
  primary: 'text-primary-1',
};

const Heading = ({
  className: additionalClassName,
  tag: Tag,
  size,
  theme,
  withHighlightedWords,
  asHTML,
  children,
}) => {
  const className = classNames(
    commonClassNames,
    sizeClassNames[size],
    themeClassNames[theme],
    withHighlightedWords && 'heading-with-highlighted-words',
    additionalClassName
  );

  if (asHTML) {
    return <Tag className={className} dangerouslySetInnerHTML={{ __html: children }} />;
  }

  return <Tag className={className}>{children}</Tag>;
};

Heading.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(sizeClassNames)).isRequired,
  theme: PropTypes.oneOf(Object.keys(themeClassNames)),
  withHighlightedWords: PropTypes.bool,
  asHTML: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
  className: null,
  withHighlightedWords: false,
  asHTML: false,
  theme: 'black',
};

export default Heading;
