import { Popover, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import Logo from 'images/logo.inline.svg';

import Button from '../button';

const MobileMenu = ({ isOpen, items }) => (
  <Transition
    show={isOpen}
    as={Fragment}
    enter="duration-150 ease-out"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
  >
    <Popover.Panel
      className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
      focus
      static
    >
      <div className="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
        <div className="flex items-center justify-between px-5 pt-4">
          <div>
            <Logo className="w-auto h-8" />
          </div>
          <div className="-mr-2">
            <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-50 focus:outline-none">
              <span className="sr-only">Close menu</span>
              <XIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
        </div>
        <div className="px-2 pt-2 pb-3">
          {items.map(({ label, to, items: nestedItems }) => (
            <>
              <a
                key={label}
                href={to}
                className="block px-3 py-2 font-medium text-black transition-colors duration-200 rounded-md hover:text-gray-3 hover:bg-gray-50"
              >
                {label}
              </a>
              {nestedItems &&
                nestedItems.map(({ label, to }) => (
                  <a
                    key={label}
                    href={to}
                    className="block px-8 py-2 font-normal text-black transition-colors duration-200 rounded-md hover:text-gray-3 hover:bg-gray-50"
                  >
                    {label}
                  </a>
                ))}
            </>
          ))}
        </div>
        <Button theme="black" size="md" className="justify-center w-full" to="" target="">
          Get started
        </Button>
      </div>
    </Popover.Panel>
  </Transition>
);

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.elementType.isRequired,
          label: PropTypes.string.isRequired,
          description: PropTypes.string,
          to: PropTypes.string.isRequired,
        }).isRequired
      ),
    }).isRequired
  ).isRequired,
};

export default MobileMenu;
