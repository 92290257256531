export default {
  404: {
    title: 'Yobi - 404',
    description: 'Page has not found',
  },
  about: {
    title: 'Yobi – About',
    description: '',
  },
  'behavior-genome': {
    title: 'Yobi – Behavior Genome',
    description: '',
  },
  'data-monetization': {
    title: 'Yobi – Monetization',
    description:
      'Introducing a new way to monetize data without sacrificing customer privacy. Yobi’s Behavioral Genome extracts signal from datasets without revealing personal or descriptive information. Companies can use Yobi’s technology to protect customer privacy in addition to preventing the commoditization of their valuable data asset.',
  },
  'data-science': {
    title: 'Yobi – Data Science',
    description:
      'Yobi centralizes the acquisition and maintenance of third party datasets, eliminating the complexity created by managing multiple partners, varying quality and technical capability, and unclear ethical motivations.',
  },
  finance: {
    title: 'Yobi – Finance',
    description:
      'Uncover valuable trends from an individual perspective as opposed to traditional aggregate market data.',
  },
  growth: {
    title: 'Yobi – Growth',
    description: 'Yobi Powers Privacy Centric Businesses.',
  },
  home: {
    title: 'Yobi – Harness the Power of Predictive Data Science',
    description: 'Optimize revenue and customer experience in a privacy centric ecosystem.',
  },
  intelligence: {
    title: 'Yobi – Intelligence',
    description:
      'Access critical data needed to understand customer decision making and competitive performance',
  },
  'marketing-advertising': {
    title: 'Yobi – Marketing',
    description:
      'Yobi has produced the first dynamic, privacy preserving AI model of consumer behavior. Our representations can be utilized to improve customer experience, advertising performance, insights, and modeling.',
  },
  modeling: {
    title: 'Yobi – Modeling',
    description: '',
  },
  personalization: {
    title: 'Yobi – Personalization',
    description: 'Dynamically personalize every event',
  },
  platform: {
    title: 'Yobi – Platform',
    description:
      'Yobi centralizes the acquisition and maintenance of third party datasets, eliminating the complexity created by managing multiple partners, varying quality and technical capability, and unclear ethical motivations.',
  },
  solutions: {
    title: 'Yobi – Solutions',
    description: 'Leverage Yobi’s Technology to unlock revenue and grow your business',
  },
  saas: {
    activate: {
      index: {
        title: 'Yobi – SaaS Activation',
        description:
          'Yobi’s SaaS platform allows companies to access the power of Yobi’s technology without the need for internal data science resources. Yobi’s SaaS platform is a turnkey solution that can be deployed in days.',
      },
      success: {
        title: 'Yobi – SaaS Activation - Success',
        description:
          'Yobi’s SaaS platform allows companies to access the power of Yobi’s technology without the need for internal data science resources. Yobi’s SaaS platform is a turnkey solution that can be deployed in days.',
      },
    },
  },
};
